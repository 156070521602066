$(document).ready(function() {
    //carousel
    $('.hero-carousel').owlCarousel({
        items: 1,
        nav: true,
        navText: ["<img src='img/arrow-left.png'>", "<img src='img/arrow-right.png'>"],
        margin: 10,
        loop: true,
        onInitialized: counterHero, //When the plugin has initialized.
        onTranslated: counterHero
    });

    function counterHero() {
        $('.hero-carousel .current').html($('.hero-carousel .owl-item.active').children().data('index') + 1);
    }

    $('.hero-carousel .owl-nav').append($(".counter"));
    
    let total = $('.hero-carousel .owl-item').not('.cloned').length;
    
    $('.counter .total').html(total);
    
    $('.counter .current').html('1');
    
    $('.hero-carousel .owl-nav button').click(function () {
        $('.counter .current').html($('.hero-carousel .owl-item.active').children().data('index') + 1);
    });

    //cuisine
    $('.cuisine').owlCarousel({
        items: 1,
        nav: true,
        dots: true,
        navText: ["<img src='img/arrow-left.png'>", "<img src='img/arrow-right.png'>"],
        loop: true,
        center: true,
    });

    $('.lounge').owlCarousel({
        startPosition: 0,
        loop: true,
        margin: 45,
        center: true,
        // autoplay: true,
        nav: true,
        navText: ["<img src='img/arrow-left.png'><div class='counter-prev'></div>", "<img src='img/arrow-right.png'><div class='counter-next'></div>"],
        responsiveClass: true,
        autoplayTimeout: 6000,
        onInitialized: counter,
        onTranslate: resetProgressBar,
        onTranslated: counter,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2,
            }
        },
    });
    
    $('#lounge .owl-nav .owl-prev .counter-prev').html('<span id="prev">' + $('.lounge .owl-item:not(".cloned")').length + '</span><span>/ ' + $('.lounge .owl-item:not(".cloned")').length + '</span>')
    $('#lounge .owl-nav .owl-next .counter-next').html('<span id="next">' + 2 + '</span><span>/ ' + $('.lounge .owl-item:not(".cloned")').length + '</span>');

    function getProgressBar() {
        return $("#sample-progress-bar").clone().removeAttr('id');
    }

    function startProgressBar(event) {
        const mainContainer = $('.lounge');
        const centerItem = mainContainer.find('.owl-item.center');
        const items = mainContainer.find('.owl-item').not('.center');
        centerItem.find('.north-location').addClass("north-visible");
        centerItem.find('.north-location').removeClass("north-hidden");
        items.find('.north-location').addClass("north-hidden");
        items.find('.north-location').removeClass("north-visible");

        if (!(centerItem.find(".slide-progress").length)) {
            centerItem.append(getProgressBar());
        }

        centerItem.find('.bar').css({
            width: "100%",
            transition: "width 10000ms"
        });
    }

    function resetProgressBar() {
        const mainContainer = $('.lounge');
        const centerItem = mainContainer.find('.owl-item.center');

        centerItem.find('.bar').css({
            width: 0,
            transition: "width 0s"
        });
        mainContainer.find(".slide-progress").css({
            // background: 'transparent',
        });
    }

    function counter(event) {
        startProgressBar();

        const current = $('.lounge .owl-item.active.center').children().data('index') +  1;

        let next = current + 1 > $('.lounge .owl-item:not(".cloned")').length ? 1 : current + 1;
        let prev = current - 1 <= 0 ? $('.lounge .owl-item:not(".cloned")').length : current - 1;

        $('#lounge .owl-nav .owl-prev .counter-prev #prev').html(prev);
        $('#lounge .owl-nav .owl-next .counter-next #next').html(next);
        
        $('#lounge #timer span').html(current < 10 ? `.0${current}` : `.${current}`);
    }

    $('.blog').owlCarousel({
        loop: true,
        nav: true,
        items: 1,
        margin: 10,
        responsiveClass: true,
        navText: ["<img src='img/arrow-left.png'>", "<img src='img/arrow-right.png'>"],
        onInitialized: counterBlog, //When the plugin has initialized.
        onTranslated: counterBlog
    });

    $('.blog .owl-nav').append($(".counterr"));
    let totalItemsBlog = $('.owl-carousel.blog .owl-item:not(".cloned")').length;
    $('.counterr').html(`<span class="current">1</span>` + ` / ` + `<span class="total">${totalItemsBlog}</span>`);
    
    $('#time .count').html('01');

    function counterBlog() {
        let currentIndexItemBlog = $('.owl-carousel.blog .owl-item.active').children().data('index') + 1;
        $('.owl-carousel.blog .current').html(currentIndexItemBlog);
        $('#time .count').html(currentIndexItemBlog < 10 ? `0${currentIndexItemBlog}` : `${currentIndexItemBlog}`);
    }


    //socialmedia
    $('.social').owlCarousel({
        loop: true,
        margin: 10,
        responsive: {
            991: {
                items: 1,
                nav: false,
                dots: false,
            },
        }
    })
    $('.media').owlCarousel({
        loop: true,
        margin: 150,
        dots: true,
        nav: true,
        navText: ["<img src='img/arrow-left.png'>", "<img src='img/arrow-right.png'>"],
        items: 1,
    })

});